import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
export const query = graphql`
    query MyQuery {
      wordPress {
        projects {
          nodes {
            title
            slug
          }
        }
      }
    }
`;

const IndexPage = ({ data }) => console.log(data) || (
  <Layout>
    <SEO title="Home" />
    <h1>Projekt</h1>
    <div>
      {data.wordPress.projects.nodes.map(project => {
        return <Link to={project.slug}>{project.title}</Link>
      })}
    </div>
  </Layout>
)

export default IndexPage
